<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid class="incident-entities-tab">
    <v-row justify="center" class="mb-6">
      <v-card class="date-range-selector" elevation="8">
        <v-card-text>
          <date-chip-group-relative
            label="Time Range"
            v-model="selectedDateTime"
            @input="onSelectedDateTimeChange"
          />
        </v-card-text>
      </v-card>
    </v-row>

    <v-fade-transition>
      <div>
        <v-row v-if="sortedEntities && sortedEntities.length > 1" class="entity-grid">
          <v-col v-for="entity in sortedEntities" :key="entity.id" cols="12" sm="6" md="4">
            <incident-entity-card
              :entity="entity"
              :count="entity.count"
              :selectedDateTime="selectedDateTime"
              :chartColor="chartColor"
            />
          </v-col>
        </v-row>

        <v-row v-else-if="sortedEntities && sortedEntities.length === 1" class="connected-cards">
          <!-- Content for single entity case -->
          <!-- ... (rest of the single entity content) ... -->
          <v-col cols="12" md="4">
            <incident-entity-card
              :entity="sortedEntities[0]"
              :count="sortedEntities[0].count"
              :selectedDateTime="selectedDateTime"
              :chartColor="chartColor"
            />
          </v-col>
          <v-col cols="12" md="4" class="entity-carousel">
            <v-card class="carousel-card" elevation="3">
              <v-carousel
                v-model="carouselIndex"
                hide-delimiters
                :show-arrows="false"
                height="700"
                class="carousel-content"
                :dark="$vuetify.theme.dark"
                :light="!$vuetify.theme.dark"
              >
                <v-carousel-item
                  v-for="occurrence in paginatedOccurrences"
                  :key="occurrence.id"
                >
                <v-card-title class="d-flex align-center">
                  <span class="text-truncate">eid-{{ sortedEntities[0].id }}__eoid-{{ occurrence.id }}</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="chat-button ml-2"
                    color="primary"
                    @click="initiateChat(occurrence)"
                    icon
                    small
                  >
                    <v-icon>mdi-chat-processing-outline</v-icon>
                  </v-btn>
                  </v-card-title>
                  <v-card-subtitle class="carousel-subtitle">
                    <v-chip small label class="mr-2 mt-8">
                      <v-icon left small>mdi-shape-outline</v-icon>
                      Type: {{ occurrence.occurrence_type }}
                    </v-chip>
                    <v-chip small label class="mr-2 mt-8">
                      <v-icon left small>mdi-calendar</v-icon>
                      Date: {{ new Date(occurrence.occurred_at).toLocaleString() }}
                    </v-chip>
                  </v-card-subtitle>
                  <v-card-text class="carousel-text">
                    <div class="scrollable-content">
                      <div v-if="extractedLinks(occurrence.description).length > 0" class="mb-3 links-section">
                        <h3>Links</h3>
                        <v-chip-group column>
                          <v-chip v-for="link in extractedLinks(occurrence.description)" :key="link" :href="link" target="_blank" small outlined>
                            {{ link }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                      <div class="summary-section">
                        <h3>Overall Summary</h3>
                        <p v-if="occurrence.entity_occurrence_raw.overallSummary">
                          {{ occurrence.entity_occurrence_raw.overallSummary }}
                        </p>
                        <p v-else class="no-summary">No summary available.</p>
                      </div>
                      <div class="description-section">
                        <h3>Full Description</h3>
                        <p v-html="convertLinks(occurrence.description)"></p>
                      </div>
                      <div class="meta-section">
                        <v-chip small label class="mr-2">
                          <v-icon left small>mdi-identifier</v-icon>
                          EOID: {{ occurrence.id }}
                        </v-chip>
                        <v-chip small label class="mr-2">
                          <v-icon left small>mdi-shape-outline</v-icon>
                          Type: {{ occurrence.occurrence_type }}
                        </v-chip>
                        <v-chip small label>
                          <v-icon left small>mdi-calendar</v-icon>
                          Date: {{ new Date(occurrence.occurred_at).toLocaleString() }}
                        </v-chip>
                      </div>
                    </div>
                  </v-card-text>
                </v-carousel-item>
              </v-carousel>
              <v-card-actions class="carousel-actions">
                <v-btn icon @click="prev">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-pagination
                  v-model="currentPage"
                  :length="pages"
                  circle
                  :total-visible="5"
                  @input="goToPage"
                ></v-pagination>
                <v-spacer></v-spacer>
                <v-btn icon @click="next">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" class="raw-content-card">
            <v-card class="raw-content" elevation="3">
              <v-card-title class="d-flex align-center">
                <span>IAO Dimensions</span>
                <v-spacer></v-spacer>
                <v-btn
                  class="chat-button ml-2"
                  color="primary"
                  @click="initiateChat(paginatedOccurrences[carouselIndex])"
                  icon
                  small
                >
                  <v-icon>mdi-chat-processing-outline</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="scrollable-content">
                <v-expansion-panels popout>
                  <v-expansion-panel
                    v-for="(key, index) in visibleKeys"
                    :key="`${index}-${key}`"
                    :value="activePanels.includes(index)"
                  >
                    <v-expansion-panel-header>{{ key }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-if="Array.isArray(paginatedOccurrences[carouselIndex]?.entity_occurrence_raw[key])">
                        <div
                          v-for="(item, itemIndex) in paginatedOccurrences[carouselIndex]?.entity_occurrence_raw[key]"
                          :key="itemIndex"
                          class="chip-content mb-2"
                        >
                          {{ item }}
                        </div>
                      </div>
                      <div v-else-if="typeof paginatedOccurrences[carouselIndex]?.entity_occurrence_raw[key] === 'object' && paginatedOccurrences[carouselIndex]?.entity_occurrence_raw[key] !== null">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr v-for="(subValue, subKey) in paginatedOccurrences[carouselIndex]?.entity_occurrence_raw[key]" :key="`${subKey}-${subValue}`">
                                <td class="text-wrap">{{ subKey }}</td>
                                <td class="text-wrap">{{ subValue }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                      <div v-else class="text-wrap">
                        {{ paginatedOccurrences[carouselIndex]?.entity_occurrence_raw[key] }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-alert
        v-else
        prominent
        type="info"
        class="mt-4 welcome-alert cosmic-alert"
        border="left"
        colored-border
        elevation="2"
      >
        <div class="cosmos-animation">
          <div class="stars"></div>
          <div class="shooting-stars"></div>
          <div class="planet"></div>
          <div class="moon"></div>
          <div class="asteroid"></div>
          <div class="satellite"></div>
          <div class="comet"></div>
        </div>
        <v-row align="center">
          <v-col class="grow">
            <h3 class="headline">Welcome to Your Knowledge Universe!</h3>
            <p class="subtitle-1">
              Your personalized index is empty. Let's start building your unique knowledge base:
            </p>
            <ol class="mt-2">
              <li>Click the 'Craft your Knowledge Universe' button below</li>
              <li>Start a conversation or ask a question</li>
              <li>Like helpful responses to add them to your index</li>
              <li>Or, just upload a file or text to get started!</li>
            </ol>
            <p class="body-2 mt-2">
              As you interact, upload content, or like responses, your knowledge universe will grow, making future searches more powerful and personalized!
            </p>
            <p class="body-2 mt-2">
              <strong>IAOs:</strong> As you interact, you'll start building interactive active objects (IAOs) that you can engage with for even more personalized, narrow, contextual information.
            </p>
            <p class="body-2 mt-2">
              <strong>Please Note:</strong> You need a Pro subscription to access this feature.
            </p>

            <v-expansion-panels class="mt-4">
              <v-expansion-panel>
                <v-expansion-panel-header class="cosmic-panel-header">
                  Understand Your Knowledge Universe Structure
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="d-flex flex-column align-center">
                    <svg width="400" height="300" viewBox="0 0 400 300" class="knowledge-universe-diagram">
                      <defs>
                        <radialGradient id="universeGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                          <stop offset="0%" style="stop-color:rgb(60,60,120);stop-opacity:1" />
                          <stop offset="100%" style="stop-color:rgb(20,20,40);stop-opacity:1" />
                        </radialGradient>
                      </defs>

                      <!-- Universe -->
                      <circle cx="200" cy="150" r="140" fill="url(#universeGradient)" />
                      <text x="200" y="30" text-anchor="middle" fill="#004bfb" font-size="16">Universe (Organization)</text>

                      <!-- Galaxy -->
                      <circle cx="200" cy="150" r="110" fill="none" stroke="#4B0082" stroke-width="2" stroke-dasharray="5,5" />
                      <text x="200" y="60" text-anchor="middle" fill="#FFF" font-size="14">Galaxy (Project)</text>

                      <!-- Planet -->
                      <circle cx="200" cy="150" r="80" fill="#008000" stroke="#004bfb" stroke-width="2" opacity="0.7" />
                      <text x="200" y="150" text-anchor="middle" fill="#FFF" font-size="12">Planet (Topic)</text>

                      <!-- Sub-planet/Moon -->
                      <circle cx="260" cy="180" r="30" fill="#FFA500" stroke="#004bfb" stroke-width="2" opacity="0.8" />
                      <text x="260" y="175" text-anchor="middle" fill="#000" font-size="10">Moon</text>
                      <text x="260" y="190" text-anchor="middle" fill="#000" font-size="10">(Sub-topic)</text>

                      <!-- IAO -->
                      <circle cx="160" cy="200" r="20" fill="#FF4500" stroke="#004bfb" stroke-width="2" />
                      <text x="160" y="205" text-anchor="middle" fill="#FFF" font-size="10">IAO</text>

                      <!-- Entity -->
                      <circle cx="230" cy="120" r="15" fill="#FFD700" stroke="#004bfb" stroke-width="2" />
                      <text x="230" y="125" text-anchor="middle" fill="#000" font-size="8">Entity</text>
                    </svg>
                  </div>

                  <p class="mt-4">Your knowledge is organized in this cosmic hierarchy:</p>
                  <ul>
                    <li><strong>Universes:</strong> Organizations or main domains of knowledge.</li>
                    <li><strong>Galaxies:</strong> Major projects within a universe.</li>
                    <li><strong>Planets:</strong> Individual topics or subjects within a project.</li>
                    <li><strong>Sub-planets/Moons:</strong> Sub-topics or related concepts within a topic.</li>
                    <li><strong>Interactive Active Objects (IAOs):</strong> Living, breathing things within your knowledge structure.</li>
                    <li><strong>Entities:</strong> Actual data broken into manageable data objects.</li>
                  </ul>
                  <p>As you add information, it will automatically (based on your settings) be categorized into this hierarchy, allowing for intuitive navigation and powerful, context-aware searches. The structure enables you to organize from broad concepts (Universes) down to specific data points (Entities), with IAOs providing dynamic, interactive elements throughout.</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div class="cosmic-btn-container">
              <v-btn
                color="tips"
                class="mt-4 cosmic-btn"
                @click="beginJourney"
                outlined
              >
                <v-icon left class="begin-journey-icon">mdi-school-outline</v-icon>
                Craft your Knowledge Universe
              </v-btn>
            </div>
          </v-col>
          <v-col class="shrink">
            <v-icon
              large
              color="info"
            >
              mdi-lightbulb-on
            </v-icon>
          </v-col>
        </v-row>
      </v-alert>
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import IncidentEntityCard from "@/entity/IncidentEntityCard.vue"
import DateChipGroupRelative from "@/components/DateChipGroupRelative.vue"
import EntityOccurrenceRawDialog from "@/entity/EntityOccurrenceRawDialog.vue"

import { mapActions } from "vuex";

export default {
  name: "IncidentEntitiesTab",
  components: {
    IncidentEntityCard,
    DateChipGroupRelative,
    EntityOccurrenceRawDialog,
  },
  props: {
    entities: {
      type: Array,
      required: true,
    },
    chartColor: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      selectedDateTime: 7,
      carouselIndex: 0,
      currentPage: 1,
      itemsPerPage: 10,
      activePanels: [],
      visibleKeys: [],
      targetEoid: null,
      eoidToIndexMap: {},
      keysInProgress: false,
    }
  },
  computed: {
    sortedEntities() {
      if (!this.entities || this.entities.length === 0) {
        return [];
      }
      const entitiesCopy = this.entities.slice();
      return entitiesCopy.sort((a, b) => {
        const aDate = a.occurrences && a.occurrences.length > 0 ? new Date(a.occurrences[0].occurred_at) : new Date(0);
        const bDate = b.occurrences && b.occurrences.length > 0 ? new Date(b.occurrences[0].occurred_at) : new Date(0);
        return bDate - aDate;
      });
    },
    pages() {
      return Math.ceil(this.deduplicatedOccurrences.length / this.itemsPerPage);
    },
    deduplicatedOccurrences() {
      if (!this.sortedEntities || this.sortedEntities.length === 0) {
        return []; // Return an empty array if there are no sorted entities
      }

      const seen = new Set();
      return this.sortedEntities[0].occurrences.filter(occurrence => {
        if (!occurrence.entity_occurrence_raw || !occurrence.entity_occurrence_raw.overallSummary) {
          return false; // Skip occurrences without a valid overallSummary
        }
        const summary = occurrence.entity_occurrence_raw.overallSummary;
        if (seen.has(summary)) {
          return false;
        }
        seen.add(summary);
        return true;
      });
    },
    paginatedOccurrences() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.deduplicatedOccurrences.slice(start, end);
    },
    sortedKeys() {
      const occurrence = this.paginatedOccurrences[this.carouselIndex];
      if (occurrence && occurrence.entity_occurrence_raw) {
        return [...new Set(Object.keys(occurrence.entity_occurrence_raw))].sort();
      }
      return [];
    }
  },
  watch: {
    carouselIndex(newIndex) {
      this.updateVisibleKeys();
    },
    paginatedOccurrences(newOccurrences) {
      this.updateVisibleKeys();
    },
    currentPage(newPage) {
      this.updateVisibleKeys();
    }
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    openChat() {
      this.$router.push({ name: 'chat' });
    },
    beginJourney() {
      this.openChat();
    },
    initiateChat(occurrence) {
      this.setActiveObject({
        type: occurrence.occurrence_type,
        id: occurrence.occurrence_bao_id,
      });
    },
    updateVisibleKeys() {
      if (this.keysInProgress) {
        return;
      }
      this.visibleKeys = this.sortedKeys;
      this.activePanels = [];
    },
    convertLinks(text) {
      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
      return text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    },
    extractedLinks(text) {
      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
      return text.match(urlPattern) || [];
    },
    isDarkTheme() {
      return localStorage.getItem("dark_theme") === 'true';
    },
    onSelectedDateTimeChange(newValue) {
      this.selectedDateTime = newValue
    },
    prev() {
      if (this.carouselIndex > 0) {
        this.carouselIndex--;
      }
    },
    next() {
      if (this.carouselIndex < this.paginatedOccurrences.length - 1) {
        this.carouselIndex++;
      }
    },
    goToPage(page) {
      this.currentPage = page;
      this.carouselIndex = 0; // Reset carousel index when page changes
      this.updateVisibleKeys(); // Ensure visible keys are updated when page changes
    },
    createEoidToIndexMap() {
      this.deduplicatedOccurrences.forEach((occurrence, index) => {
        this.eoidToIndexMap[occurrence.id] = index;
      });
    },
    goToEoid(eoid) {
      const occurrenceIndex = this.eoidToIndexMap[eoid];
      if (occurrenceIndex !== undefined) {
        this.currentPage = Math.floor(occurrenceIndex / this.itemsPerPage) + 1;
        this.carouselIndex = occurrenceIndex % this.itemsPerPage;
        this.updateVisibleKeys(); // Update visible keys to match the new occurrence
      }
    }
  },
  mounted() {
    // In problematic components
    const isDark = localStorage.getItem("dark_theme") === "true"
    if (isDark || !localStorage.getItem("dark_theme")) {
      this.$vuetify.theme.dark = true
    }
    this.createEoidToIndexMap();
    const urlParams = new URLSearchParams(window.location.search);
    const eoidParam = urlParams.get('q');
    if (eoidParam) {
      const eoidMatch = eoidParam.match(/eoid-(\d+)/);
      if (eoidMatch) {
        this.targetEoid = parseInt(eoidMatch[1], 10);
      }
    }
    if (this.targetEoid) {
      this.$nextTick(() => {
        this.goToEoid(this.targetEoid);
      });
    }
    // Fetch visible keys after mounted to ensure that the carousel is rendered
    this.updateVisibleKeys();
  }
}
</script>

<style scoped>
.incident-entities-tab {
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
}
.connected-cards {
  position: relative;
  justify-content: space-between;
}

.connected-cards::before, .connected-cards::after {
  content: "";
  position: absolute;
  top: 50%;
  border-top: 2px solid rgba(0, 198, 255, 0.3);
  transform: translateY(-50%);
  z-index: 0; /* Changed from 1 to 0 */
}

.connected-cards::before {
  left: 33.33%; /* Changed from 12px */
  right: 66.66%;
}

.connected-cards::after {
  left: 66.66%; /* Changed from 33.33% */
  right: 33.33%; /* Changed from 12px */
}

.entity-carousel, .raw-content-card {
  margin-left: 0;
  position: relative;
  z-index: 1; /* Added to ensure cards are above the lines */
}

.raw-content-card .scrollable-content {
  height: 100%;
  overflow-y: auto;
  font-size: 15px;
}

.carousel-text, .raw-content .scrollable-content {
  height: calc(100%);
  overflow-y: auto;
}

.carousel-card, .raw-content {
  backdrop-filter: blur(10px);
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 800px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1; /* Added to ensure cards are above the lines */
}

.theme--dark .carousel-card, .theme--dark .raw-content {
  background: linear-gradient(90deg, rgba(230, 240, 255, .1) 0, rgba(230, 240, 255, .2) 50%, rgba(230, 240, 255, .1));
}

.carousel-text {
  overflow-y: auto;
}

.carousel-card:hover, .raw-content:hover {
  box-shadow: 0 12px 48px rgba(0, 198, 255, 0.2);
}

.carousel-content {
  flex-grow: 1;
}

.carousel-subtitle, .text-caption {
  color: rgba(255, 255, 255, 0.7) !important;
}

.scrollable-content {
  height: calc(100% - 120px);
  overflow-y: auto;
  padding: 16px;
}

.scrollable-content::-webkit-scrollbar {
  width: 6px;
}

.v-chip {
  background: rgba(0, 198, 255, 0.1) !important;
  transition: all 0.3s ease;
}

.v-chip:hover {
  background: rgba(0, 198, 255, 0.2) !important;
  box-shadow: 0 0 15px rgba(0, 198, 255, 0.3);
}

.chip-content {
  border-radius: 8px;
  padding: 8px 12px;
  word-break: break-word;
  white-space: normal;
  line-height: 1.5;
  font-size: 0.875rem;
}

.v-data-table {
  background: transparent !important;
}

.chat-button {
  transition: all 0.3s ease;
}

.chat-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(0, 198, 255, 0.5);
}

.v-tooltip__content {
  color: #004bfb !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  max-width: 300px !important;
  padding: 8px 12px !important;
}

.scrollable-content a {
  color: #004bfb;
  text-decoration: none;
  transition: all 0.3s ease;
}

.scrollable-content a:hover {
  text-shadow: 0 0 8px rgba(0, 198, 255, 0.5);
}

.date-range-selector {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.theme--dark .date-range-selector {
  background: linear-gradient(90deg, rgba(230, 240, 255, .1) 0, rgba(230, 240, 255, .2) 50%, rgba(230, 240, 255, .1));
}

.date-range-selector .v-card__text {
  position: relative;
  z-index: 1;
}

.date-range-selector .v-chip {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  transition: all 0.3s ease;
}

.date-range-selector .v-chip:hover,
.date-range-selector .v-chip.v-chip--active {
  background-color: rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.entity-grid {
  margin-left: -12px;
  margin-right: -12px;
}

.welcome-alert {
  border-left-color: #2196f3 !important;
}

.welcome-alert .headline {
  color: #1565c0;
  margin-bottom: 10px;
}

.welcome-alert ol {
  padding-left: 20px;
}

.welcome-alert li {
  margin-bottom: 5px;
}

.welcome-alert .v-icon {
  font-size: 48px;
}

.begin-journey-icon {
  font-size: 24px !important;
  margin-right: 15px !important;
}


.cosmic-alert {
  position: relative;
  overflow: hidden;
  min-height: 400px;
}

.cosmos-animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.cosmic-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2rem;
}

.cosmic-btn {
  background: rgba(100, 255, 218, 0.1);
  border-color: #64ffda;
  color: #64ffda;
  transition: all 0.3s ease;
}

.cosmic-btn:hover {
  background: rgba(100, 255, 218, 0.2);
  box-shadow: 0 0 15px rgba(100, 255, 218, 0.5);
  transform: translateY(-3px);
}
.stars {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image:
    radial-gradient(2px 2px at 20px 30px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 50px 160px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 90px 40px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 130px 80px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 160px 120px, #fff, rgba(0,0,0,0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation: twinkle 4s infinite;
  opacity: 0.5;
}

.shooting-stars {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: shootingStars 10s linear infinite;
}

.shooting-stars::before, .shooting-stars::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 1px;
  background: linear-gradient(to right, rgba(0,0,0,0), #fff, rgba(0,0,0,0));
  transform: rotate(-45deg);
}

.shooting-stars::before {
  top: 20%;
  left: -50%;
  animation: shoot 5s linear infinite;
}

.shooting-stars::after {
  top: 60%;
  left: -50%;
  animation: shoot 7s linear infinite;
}

.planet {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(45deg, #64ffda, #0a192f);
  box-shadow: 0 0 20px rgba(100, 255, 218, 0.5);
  animation: float 12s infinite ease-in-out;
  right: 10%;
  top: 20%;
}

.moon {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ccc;
  box-shadow: 0 0 10px rgba(204, 204, 204, 0.5);
  animation: orbit 20s linear infinite;
}

.asteroid {
  position: absolute;
  width: 20px;
  height: 15px;
  background: #8B4513;
  border-radius: 50% 20% 50% 20%;
  animation: rotate 4s linear infinite, float 8s infinite ease-in-out;
  left: 15%;
  top: 60%;
}

.satellite {
  position: absolute;
  width: 40px;
  height: 20px;
  background: #696969;
  border-radius: 10px;
  animation: orbit 30s linear infinite;
  transform-origin: 150% 50%;
}

@keyframes twinkle {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

@keyframes shootingStars {
  0% { transform: translateX(0); }
  100% { transform: translateX(100%); }
}

@keyframes shoot {
  0% { transform: translateX(0) rotate(-45deg); opacity: 0; }
  5% { opacity: 1; }
  100% { transform: translateX(600px) rotate(-45deg); opacity: 0; }
}

@keyframes float {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(180deg); }
}

@keyframes orbit {
  0% { transform: rotate(0deg) translateX(150px) rotate(0deg); }
  100% { transform: rotate(360deg) translateX(150px) rotate(-360deg); }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.planet {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(45deg, #ff6b6b, #4ecdc4);
  box-shadow: 0 0 30px rgba(255, 107, 107, 0.6);
  animation: float 12s infinite ease-in-out;
  right: 10%;
  top: 20%;
}

.moon {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: linear-gradient(45deg, #f7d794, #786fa6);
  box-shadow: 0 0 15px rgba(247, 215, 148, 0.7);
  animation: orbit 20s linear infinite;
}

.asteroid {
  position: absolute;
  width: 20px;
  height: 15px;
  background: linear-gradient(45deg, #e056fd, #686de0);
  border-radius: 50% 20% 50% 20%;
  box-shadow: 0 0 10px rgba(224, 86, 253, 0.6);
  animation: rotate 4s linear infinite, float 8s infinite ease-in-out;
  left: 15%;
  top: 60%;
}

.satellite {
  position: absolute;
  width: 40px;
  height: 20px;
  background: linear-gradient(90deg, #ff9ff3, #feca57);
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(255, 159, 243, 0.7);
  animation: orbit 30s linear infinite;
  transform-origin: 150% 50%;
}

.comet {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 20px 4px #54a0ff;
  animation: cometMove 15s linear infinite;
  opacity: 0;
}

.comet::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 2px;
  background: linear-gradient(to right, #54a0ff, transparent);
}

@keyframes cometMove {
  0% {
    top: -10%;
    left: -10%;
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    top: 110%;
    left: 110%;
    opacity: 0;
  }
}

.cosmic-panel-header {
  background-color: rgba(0, 198, 255, 0.1) !important;
  color: #004bfb !important;
  border: 1px solid rgba(0, 198, 255, 0.5) !important;
  margin-top: 1rem;
}

.knowledge-universe-diagram {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

.knowledge-universe-diagram text {
  font-family: Arial, sans-serif;
  pointer-events: none;
}

.cosmic-panel-header {
  background-color: rgba(0, 198, 255, 0.1) !important;
  color: #004bfb !important;
  border: 1px solid rgba(0, 198, 255, 0.5) !important;
}

.v-expansion-panel-content {
  border-radius: 8px;
  padding: 8px 16px;
}

.theme--dark .v-expansion-panel-content {
  background: linear-gradient(90deg, rgba(230, 240, 255, .1) 0, rgba(230, 240, 255, .2) 50%, rgba(230, 240, 255, .1));
}
</style>
